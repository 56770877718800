import React from "react";
import AddNewJournal from "./AddNewJournal/AddNewJournal";

const AddJournalPage = () => {
  return (
    <div>
      <h4 className=" fs_25 ff-gotham-bold color_black2 fw-bold  my-3">
        Add Journals
      </h4>
      <AddNewJournal />
    </div>
  );
};

export default AddJournalPage;
