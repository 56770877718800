import React from "react";
import "./AppHero.css";
import nextArrow from "../../../assets/image/png/nextArrow.png";
import mainImg from "../../../assets/image/png/heroImg.png";
import stars from "../../../assets/image/png/stars.png";
import { Button, Col, Container, Form, FormGroup, Row } from "react-bootstrap";

const AppHero = () => {
  return (
    <div className="py-5 d-flex flex-column flex-grow-1">
      <Container>
        <Row className="align-items-center">
          <Col className="mt-3 text-center text-lg-start" lg={6}>
            <div>
              <div className="d-flex align-items-center gap-4">
                <div className="hr-line"></div>
                <div className="bg-white px-3 py-1 rounded-5">
                  <p className="mb-0 ff-gotham-normal fs_20">
                    #1 Mental Fitness Platform
                  </p>
                </div>
              </div>
              <h1 className="mb-0 ff-gotham-bold fs-72">
                <span className="elevate-text">Inspire</span> Your Mind{" "}
                <span className="elevate-text">Elevate</span> Your Game
              </h1>
              <p
                style={{ opacity: "60%" }}
                className="mb-0 mt-4 ff-gotham-normal fs_16 clr-black"
              >
                Start achieving your true potential Get Inspired Today
              </p>

              {/* FORM */}

              <Form
                style={{ width: "fit-content", borderRadius: "10px" }}
                className="d-flex gap-2 bg-white align-items-center mx-auto mx-lg-0 py-1 px-1 mt-4 start-input-border"
              >
                <FormGroup>
                  <Form.Control
                    className="bg-transparent border-0 ff-gotham-normal ps-4"
                    type="email"
                    placeholder="Your Email"
                  />
                </FormGroup>
                <Button
                  style={{ width: "146px", height: "45px" }}
                  className="btn-green-common rounded-2 d-flex align-items-center gap-2 justify-content-center"
                  type="submit"
                >
                  Start Here
                  <img
                    style={{ width: "13px", height: "10px" }}
                    src={nextArrow}
                    alt="nextArrow"
                  />
                </Button>
              </Form>
            </div>
          </Col>
          <Col className="mt-4" lg={6}>
            <div className="text-center position-relative">
              <div className="text-start aura-review-box position-absolute p-xl-4 p-3">
                <div>
                  <img className="review-stars" src={stars} alt="stars" />
                  <p className="ff-gotham-normal mb-0 fs_16">Aura Laura </p>
                  <p
                    style={{ opacity: "60%" }}
                    className="mb-0 ff-gotham-normal mt-2 fs_16"
                  >
                    “Best Wellness Mentors”
                  </p>
                </div>
              </div>
              <img className="w-75" src={mainImg} alt="boyWithFootball" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AppHero;
