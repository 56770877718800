import React, { useState } from "react";
import "./Blogs.css";
import { Col, Container, Row } from "react-bootstrap";
import nextArrow from "../../../assets/image/png/nextArrowClrBlack.png";
import yoga from "../../../assets/image/png/yoga.png";
import hockey from "../../../assets/image/png/hockey.png";
import tennis from "../../../assets/image/png/tennis.png";
import gym from "../../../assets/image/png/gym.png";

const Blogs = () => {
  const [data, setData] = useState([
    {
      img: yoga,
      head: "The Power of Visualization in Sports",
      para: "Explore how visualization techniques can enhance athletic performance. Learn how top athletes use mental imagery to improve skills, boost confidence, and achieve peak performance.",
      createdAt: "2 days ago",
      readingTime: "7 min read",
    },
    {
      img: hockey,
      head: "The Power of Visualization in Sports",
      para: "Explore how visualization techniques can enhance athletic performance. Learn how top athletes use mental imagery to improve skills, boost confidence, and achieve peak performance.",
      createdAt: "2 days ago",
      readingTime: "7 min read",
    },
    {
      img: tennis,
      head: "The Power of Visualization in Sports",
      para: "Explore how visualization techniques can enhance athletic performance. Learn how top athletes use mental imagery to improve skills, boost confidence, and achieve peak performance.",
      createdAt: "2 days ago",
      readingTime: "7 min read",
    },
    {
      img: yoga,
      head: "The Power of Visualization in Sports",
      para: "Explore how visualization techniques can enhance athletic performance. Learn how top athletes use mental imagery to improve skills, boost confidence, and achieve peak performance.",
      createdAt: "2 days ago",
      readingTime: "7 min read",
    },
    {
      img: gym,
      head: "The Power of Visualization in Sports",
      para: "Explore how visualization techniques can enhance athletic performance. Learn how top athletes use mental imagery to improve skills, boost confidence, and achieve peak performance.",
      createdAt: "2 days ago",
      readingTime: "7 min read",
    },
  ]);

  return (
    <div id="Blog" className="py-5">
      <Container>
        <div className="d-flex align-items-center gap-4">
          <div className="horizontal-line"></div>
          <div className="bg-white px-3 py-1 rounded-5">
            <p className="mb-0 ff-gotham-normal fs_20">Blogs</p>
          </div>
        </div>
        <div className="d-flex align-items-lg-end justify-content-between">
          <h2 className="mb-0 ff-gotham-bold fs-56 mt-4">
            From Our <span className="elevate-text">Blog</span>
          </h2>
          <div className="d-flex align-items-center gap-2">
            <p className="mb-0 ff-poppins fw-medium fs_20 clr-black">See All</p>
            <img
              style={{ width: "14px", height: "10px" }}
              src={nextArrow}
              alt="nextArrow"
            />
          </div>
        </div>
        <Row>
          {data.map((value, index) => (
            <Col
              className="mt-4"
              lg={index === 0 ? 12 : 3}
              sm={index === 0 ? 12 : 6}
              key={index}
            >
              <div className="position-relative">
                <div className="linear-layer"></div>
                <div>
                  <img
                    className={`${
                      index === 0 ? "height-363" : ""
                    } w-100 object-fit-cover image-border-radius`}
                    src={value.img}
                    alt=""
                  />
                </div>

                <div className="content position-absolute">
                  <div className="d-flex gap-3 align-items-center">
                    <p className="text-white ff-gotham-normal fs_16 mb-0">
                      {value.createdAt}
                    </p>
                    <div className="d-flex align-items-center gap-3">
                      <div className="dot"></div>
                      <p className="text-white ff-gotham-normal fs_16 mb-0">
                        {value.readingTime}
                      </p>
                    </div>
                  </div>
                  {index === 0 && (
                    <>
                      <h4 className="text-white ff-montserrat fw-medium fs-32 my-3">
                        {value.head}
                      </h4>
                      <p
                        style={{ maxWidth: "700px" }}
                        className="text-white ff-gotham-normal fs_16 mb-0"
                      >
                        {value.para}
                      </p>
                    </>
                  )}
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Blogs;
