import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../authPages/auth.css";
import AuthHeader from "./AuthHeader";
import {
  otpVerification,
  resetRedirectPath,
} from "../../redux/slice/OtpVerificationSlice";
import { Container } from "react-bootstrap";

const OtpVerification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const inputRefs = useRef([]);

  const emailOrPhone = useSelector((state) => state.userLogin.emailOrPhone);
  const redirectPath = useSelector(
    (state) => state.otpVerification.redirectPath
  );

  useEffect(() => {
    if (redirectPath) {
      navigate(redirectPath);
      dispatch(resetRedirectPath());
    }
  }, [redirectPath, navigate, dispatch]);

  const handleChange = (element, index) => {
    if (isNaN(element.value) && element.value !== "") return;

    const value = element.value;
    const newOtp = [...otp];
    newOtp[index] = value;

    setOtp(newOtp);

    if (value && index < otp.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      if (index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    } else if (e.key === "Delete" && otp[index] === "") {
      if (index < otp.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleSubmit = () => {
    if (emailOrPhone) {
      dispatch(otpVerification({ emailOrPhone, otp: otp.join("") }));
    }
  };

  return (
    <div className="d-flex min-vh-100 flex-column">
      <div>
        <AuthHeader />
      </div>
      <Container className="d-flex py-4 flex-column flex-grow-1 justify-content-center align-items-center">
        <div className="custom_width">
          <h4 className="fw-bold text-center ff-gotham-bold fs_24 color_black2">
            OTP Verification
          </h4>
          <p
            style={{ color: "#21272A" }}
            className="fs_14 text-center mt-3 mb-0 ff-gotham-normal"
          >
            Please enter the OTP received at your email or SMS
          </p>
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-between mt-4">
              {otp.map((data, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  className="otp-input mx-1 text-center ff-gotham-bold fs_24"
                  value={data}
                  onChange={(e) => handleChange(e.target, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onFocus={(e) => e.target.select()}
                  ref={(el) => (inputRefs.current[index] = el)}
                />
              ))}
            </div>
            <span className="d-flex mt-3 justify-content-end ff-gotham-bold fs_12 color_theme">
              Resend OTP?
            </span>
          </div>
          <button
            style={{
              letterSpacing: "0.50px",
              height: "42px",
              borderRadius: "8.66px",
            }}
            className="w-100 bg_theme ff-gotham-bold text-white border-0 mt-4 rounded-2"
            onClick={handleSubmit}
          >
            Continue
          </button>
        </div>
      </Container>
    </div>
  );
};

export default OtpVerification;
