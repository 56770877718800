import React, { useEffect, useState } from "react";
import "./auth.css";
import AuthHeader from "./AuthHeader";
import { Col, Container, Row } from "react-bootstrap";
import or_icon from "../../assets/image/svg/or_icon.svg";
import facebook_icon from "../../assets/image/svg/facebook_icon.svg";
import google_icon from "../../assets/image/svg/google_icon.svg";
import ice_icon from "../../assets/image/svg/ice_icon.svg";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { userSignup, resetRedirectPath } from "../../redux/slice/UserSlice";

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = useSelector((state) => state.user.role);
  const redirectPath = useSelector((state) => state.user.redirectPath);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    parentName: "",
    parentEmail: "",
    phoneNumber: "",
    password: "",
    termsAccepted: false,
  });

  useEffect(() => {
    if (redirectPath) {
      navigate(redirectPath);
      dispatch(resetRedirectPath());
    }
  }, [redirectPath, navigate, dispatch]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = () => {
    if (formData.termsAccepted) {
      let payload = { ...formData, role: role };
      if (role === "Athlete") {
        payload = {
          ...payload,
          parentName: formData.parentName,
          parentEmail: formData.parentEmail,
        };
      } else {
        const { parentName, parentEmail, ...rest } = payload;
        payload = rest;
      }
      dispatch(userSignup(payload));
    }
  };

  useEffect(() => {
    if (!role) {
      navigate("/choose-role");
    }
  }, [role, navigate]);

  return (
    <div className="d-flex min-vh-100 flex-column">
      <div>
        <AuthHeader />
      </div>
      <Container className="d-flex py-4 flex-column flex-grow-1 justify-content-center align-items-center">
        <div className="custom_width">
          <h4 className="fw-bold text-center ff-gotham-bold fs_24 color_black2">
            Create your Inspire Fitness account
          </h4>
          <Row className="mt-4 pt-3">
            <Col md={6} className="d-flex flex-column">
              <label className="ff-gotham-bold mb-0" htmlFor="firstName">
                First Name
              </label>
              <input
                className="w-100 mt-2 custom_border ff-gotham-light py-2"
                type="text"
                style={{ height: "41px" }}
                placeholder="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
              />
            </Col>
            <Col md={6} className="d-flex flex-column">
              <label className="ff-gotham-bold mb-0" htmlFor="lastName">
                Last Name
              </label>
              <input
                className="w-100 mt-2 custom_border ff-gotham-light py-2"
                type="text"
                style={{ height: "41px" }}
                placeholder="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
              />
            </Col>
            <Col md={12} className="d-flex mt-3 flex-column">
              <label className="ff-gotham-bold mb-0" htmlFor="email">
                Email
              </label>
              <input
                className="w-100 mt-2 custom_border ff-gotham-light py-2"
                type="email"
                style={{ height: "41px" }}
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </Col>
            <Col md={12} className="d-flex mt-3 flex-column">
              <label className="ff-gotham-bold mb-0" htmlFor="phoneNumber">
                Phone Number
              </label>
              <input
                className="w-100 mt-2 custom_border ff-gotham-light py-2"
                type="number"
                style={{ height: "41px" }}
                placeholder="Phone Number"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </Col>
            {role === "Athlete" ? (
              <>
                <Col md={6} className="d-flex flex-column mt-3">
                  <label className="ff-gotham-bold mb-0" htmlFor="parentName">
                    Parent Name
                  </label>
                  <input
                    className="w-100 mt-2 custom_border ff-gotham-light py-2"
                    type="text"
                    style={{ height: "41px" }}
                    placeholder="Parent Name"
                    name="parentName"
                    value={formData.parentName}
                    onChange={handleChange}
                  />
                </Col>
                <Col md={6} className="d-flex flex-column mt-3">
                  <label className="ff-gotham-bold mb-0" htmlFor="parentEmail">
                    Parent Email
                  </label>
                  <input
                    className="w-100 mt-2 custom_border ff-gotham-light py-2"
                    type="email"
                    style={{ height: "41px" }}
                    placeholder="Parent Email"
                    name="parentEmail"
                    value={formData.parentEmail}
                    onChange={handleChange}
                  />
                </Col>
              </>
            ) : null}
            <Col md={12} className="d-flex mt-3 flex-column">
              <label className="ff-gotham-bold mb-0" htmlFor="password">
                Create Password
              </label>
              <div className="position-relative">
                <input
                  className="w-100 mt-2 custom_border ff-gotham-light py-2"
                  type="password"
                  style={{ height: "41px" }}
                  placeholder="Create Password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
                <img
                  className="position-absolute top-0 end-0 pt-4 cursor-pointer pe-3"
                  src={ice_icon}
                  alt="ice_icon"
                />
              </div>
            </Col>
          </Row>
          <div className="d-flex mt-3 align-items-center gap-2">
            <input
              type="checkbox"
              id="Accept"
              name="termsAccepted"
              checked={formData.termsAccepted}
              onChange={handleChange}
            />
            <label
              className="ff-gotham-normal fs_14 mb-0 mt-1"
              htmlFor="Accept"
            >
              Accept terms and conditions. Read T&C
            </label>
          </div>
          <button
            onClick={handleSubmit} // Update button click to handleSubmit
            style={{ height: "42px", borderRadius: "8.66px" }}
            className="w-100 bg_theme ff-gotham-bold text-white border-0 mt-4 rounded-2"
          >
            Continue
          </button>
          <div className="d-flex justify-content-center mt-4">
            <img className="" src={or_icon} alt="or_icon" />
          </div>
          <div className="d-flex mt-4 justify-content-center gap-3">
            <img
              className="cursor-pointer"
              src={google_icon}
              alt="google_icon"
            />
            <img
              className="cursor-pointer"
              src={facebook_icon}
              alt="facebook_icon"
            />
          </div>
          <p className="fs_12 text-center fw-semibold mt-3 mb-0">
            Already have an account?
            <span
              onClick={() => navigate("/sign-in")}
              className="fw-bold color_theme cursor-pointer"
            >
              {" "}
              Login
            </span>
          </p>
        </div>
      </Container>
    </div>
  );
};

export default SignUp;
