import React from "react";
import "./TryInspire.css";
import { Button, Col, Container, Row } from "react-bootstrap";
import nextArrow from "../../../assets/image/png/nextArrow.png";
import girlWearingOrangeHoodie from "../../../assets/image/png/girlWearingOrangeHoodie.png";

const TryInspire = () => {
  return (
    <div className="py-5 px-2">
      <Container
        style={{ borderRadius: "20px" }}
        className="inspire-section-bg pt-md-5 py-3 pb-3 pb-xl-0"
      >
        <Row className="ps-md-5 position-relative text-center text-md-start">
          <Col className="pe-md-5" lg={9}>
            <p className="mb-0 ff-gotham-bold fs-32">
              Try Inspire wellness for free during 14-day with no commitment.
            </p>
            <p className="mb-0 ff-gotham-bold fs-32">No credit card required</p>
            <Button
              style={{ width: "150px", height: "45px", borderRadius: "10px" }}
              className="btn-green-common d-flex align-items-center mt-4 mx-auto mx-md-0 gap-2 justify-content-center"
            >
              Start Here
              <img
                style={{ width: "13px", height: "10px" }}
                src={nextArrow}
                alt="nextArrow"
              />
            </Button>
          </Col>
          <Col lg={3} className="d-none d-md-block">
            <div>
              <img
                className="girlWearingOrangeHoodie"
                src={girlWearingOrangeHoodie}
                alt="girlWearingOrangeHoodie"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TryInspire;
