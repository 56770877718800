import React, { useState } from "react";
import Slider from "react-slick";
import image1 from "../../../../assets/image/png/meditationImage.png";
import image2 from "../../../../assets/image/png/meditationImage2.png";
import arrowNext from "../../../../assets/image/svg/sliderArrowNext.svg";
import arrowPrev from "../../../../assets/image/svg/sliderArrowPrev.svg";
import { Layout } from "antd";

const SongSlider = () => {
  const [data, setData] = useState([
    {
      img: image1,
    },
    {
      img: image2,
    },
    {
      img: image1,
    },
    {
      img: image2,
    },
  ]);
  const button = React.useRef();
  var settings = {
    autoplay: false,
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1.4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1.4,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.4,
          infinite: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          infinite: true,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings} ref={button}>
        {data.map((value, index) => (
          <div className="pe-3" key={index}>
            <img
              style={{ borderRadius: "20px" }}
              className="w-100 border-0"
              src={value.img}
              alt="songBanners"
            />
          </div>
        ))}
      </Slider>
      <Layout className="bg-transparent flex-row gap-4 justify-content-end mt-3">
        <button
          onClick={() => button.current.slickPrev()}
          className="border-0 bg-transparent"
        >
          <img src={arrowPrev} alt="arrowPrev" />
        </button>
        <button
          onClick={() => button.current.slickNext()}
          className="border-0 bg-transparent"
        >
          <img src={arrowNext} alt="arrowNext" />
        </button>
      </Layout>
    </div>
  );
};

export default SongSlider;
