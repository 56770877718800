import { all } from "redux-saga/effects";
import {
  watchOtpVerification,
  watchUserLogin,
  watchUserSignup,
} from "../saga/AuthSaga";
import {
  watchUploadFile,
  watchGetAllLibraryFile,
  watchGetUserLibraryFile,
  watchDeleteUserLibraryFiles,
} from "../saga/UserLibrarySaga";
import {
  watchDeleteJournal,
  watchFetchAllJournals,
  watchGetUserJournal,
  watchJournalDetailsSaga,
  watchJournalSubmission,
  watchUpdateJournal,
} from "../saga/JournalSaga";

export default function* rootSaga() {
  yield all([
    watchUserSignup(),
    watchUserLogin(),
    watchOtpVerification(),
    watchUploadFile(),
    watchGetAllLibraryFile(),
    watchGetUserLibraryFile(),
    watchDeleteUserLibraryFiles(),
    watchJournalSubmission(),
    watchGetUserJournal(),
    watchJournalDetailsSaga(),
    watchFetchAllJournals(),
    watchUpdateJournal(),
    watchDeleteJournal(),
  ]);
}
