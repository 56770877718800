import React from "react";
import AddFiles from "./AddFile/AddFiles";
import UserLibrary from "./UserLibrary/UserLibrary";

const AddNew = () => {
  return (
    <div>
      <AddFiles />
      <UserLibrary />
    </div>
  );
};

export default AddNew;
