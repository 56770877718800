import React, { useState } from "react";
import "./Features.css";
import { Col, Container, Row } from "react-bootstrap";
import pieChart from "../../../assets/image/png/pieChart.png";
import squareBoxes from "../../../assets/image/png/squareBoxes.png";
import tickWithGreenBg from "../../../assets/image/png/tickWithGreenBg.png";

const Features = () => {
  const [data, setData] = useState([
    {
      img: pieChart,
      head: "Personalized Mental Training Plans",
      para: "Reach peak mental fitness with a counselor crafted plan complete with techniques and strategies tailored to your unique needs",
      pointName1: "• Reach Peak Mental Fitness: ",
      point1:
        "Achieve your athletic goals with a personalized plan crafted by experienced sports counselors.",
      pointName2: "• Your Needs, Your Plan: ",
      point2:
        "Receive a personalized mental fitness plan designed specifically for you by our expert counselors.",
      pointName3: "•	Mental Fitness, Your Way: ",
      point3:
        "Get a personalized plan that fits your individual needs and athletic goals.",
    },
    {
      img: squareBoxes,
      head: "Interactive Counseling Sessions",
      para: "Access one-on-one virtual counseling with a mental health counselor certified in sports psychology in a safe and supportive environment",
      pointName1: "• Confidential Support, Peak Performance: ",
      point1:
        "Connect with a licensed mental health counselor specializing in sports psychology, in a safe and supportive virtual environment.",
      pointName2: "• Your Mental Game Matters: ",
      point2:
        "Get personalized guidance from a certified sports psychology counselor in a confidential and supportive online setting.",
      pointName3: "• Overcome Challenges, Achieve Your Goals: ",
      point3:
        "Access one-on-one virtual counseling with an experienced mental health professional who understands the unique needs of athletes.",
      pointName4: "• Thrive On and Off the Field: ",
      point4:
        "Our virtual counseling provides a safe space for athletes to address mental health concerns and reach their full potential.",
    },
    {
      img: tickWithGreenBg,
      head: "Mental Wellness Resources Library",
      para: "Your Mental Fitness Toolkit: Explore a vast library of latest research, articles, videos, and tools.",
    },
  ]);
  return (
    <div id="Features" className="bg-white py-5">
      <Container>
        <div className="d-flex align-items-center gap-4 justify-content-center">
          <hr className="horizontal-line" />
          <h3 className="ff-gotham-normal clr-black fs_20">Features</h3>
          <hr className="horizontal-line" />
        </div>
        <h2
          style={{ maxWidth: "800px" }}
          className="mb-0 ff-gotham-bold fs-56 text-center mx-auto mt-3"
        >
          <span className="elevate-text">Personalized</span> Guidance and
          Support for Every Athlete
        </h2>
        <p
          style={{ maxWidth: "500px" }}
          className="mb-0 fs_16 clr-black opacity-60 ff-gotham-normal mt-4 mx-auto text-center"
        >
          Specialized Features helping athletes reach their full potential
        </p>
        <Row className="mt-3">
          {data.map((value, index) => {
            return (
              <Col lg={4} sm={6} key={index} className="mt-4">
                <div className="feature-card cursor-pointer p-4 h-100 d-flex flex-column">
                  <div>
                    <div className="card-image-circle d-flex align-items-center justify-content-center">
                      <img className="card-image" src={value.img} alt="" />
                    </div>
                    <h4 className="ff-gotham-bold fs-30 mt-3">{value.head}</h4>
                  </div>
                  <p className="ff-gotham-normal fs-17 mb-0 mt-3">
                    {value.para}
                  </p>
                  {/* <ul className="m-0 p-0 mt-3">
                    <li className="d-flex align-items-center gap-2">
                      <p className="mb-0 ff-gotham-normal">
                        <span className="ff-gotham-bold">
                          {value.pointName1}
                        </span>
                        {value.point1}
                      </p>
                    </li>
                    <li className="d-flex align-items-center gap-2 mt-3">
                      <p className="mb-0 ff-gotham-normal">
                        <span className="ff-gotham-bold">
                          {value.pointName2}
                        </span>
                        {value.point2}
                      </p>
                    </li>
                    <li className="d-flex align-items-center gap-2 mt-3">
                      <p className="mb-0 ff-gotham-normal">
                        <span className="ff-gotham-bold">
                          {value.pointName3}
                        </span>
                        {value.point3}
                      </p>
                    </li>
                    <li className="d-flex align-items-center gap-2 mt-3">
                      <p className="mb-0 ff-gotham-normal">
                        <span className="ff-gotham-bold">
                          {value.pointName4}
                        </span>
                        {value.point4}
                      </p>
                    </li>
                  </ul> */}
                </div>
              </Col>
            );
          })}
          <Col></Col>
        </Row>
      </Container>
    </div>
  );
};

export default Features;
