import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect } from "react";
import "./App.css";
import "quill/dist/quill.snow.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@toast-ui/calendar/dist/toastui-calendar.min.css";
import "react-quill/dist/quill.snow.css";
import "react-toastify/dist/ReactToastify.css";
import ChooseRole from "./components/authPages/ChooseRole";
import SignUp from "./components/authPages/SignUp";
import SignIn from "./components/authPages/SignIn";
import OtpVerification from "./components/authPages/OtpVerification";
import FildHouseDashboard from "./pages/FildHouseDashboard";
import Dashboard from "./components/fildHouseDashboard/Dashboard";
import Session from "./components/fildHouseDashboard/Session/Session";
import Journal from "./components/fildHouseDashboard/Journal/Journal";
import AssesmentTests from "./components/fildHouseDashboard/Assesment/AssesmentTests";
import AssesmentQuestions from "./components/fildHouseDashboard/Assesment/AssesmentQuestions";
import Library from "./components/fildHouseDashboard/Library/Library";
import AddNew from "./components/fildHouseDashboard/Library/AddNew/AddNew";
import QuiteRoomPage from "./components/fildHouseDashboard/QuietRoom/QuiteRoomPage";
import LandingPage from "./pages/LandingPage";
import CoachingRoomDashboard from "./pages/CoachingRoomDashboard";
import CoachDashboard from "./components/CoachRoomDashboard/CoachDashboard/CoachDashboard";
import CoachSessions from "./components/CoachRoomDashboard/CoachSessions/CoachSessions";
import CoachLibrary from "./components/CoachRoomDashboard/CoachLibrary/CoachLibrary";
import CoachJournal from "./components/CoachRoomDashboard/Coachjournal/CoachJournal";
import CoachChatRoom from "./components/CoachRoomDashboard/CoachChatRoom/CoachChatRoom";
import CounselorDashboard from "./components/CounselorPortal/CounselorDashboard/CounselorDashboard";
import CounselorePortalDashboard from "./pages/CounselorePortalDashboard";
import CounselorJournal from "./components/CounselorPortal/CounselorJournal/CounselorJournal";
import CounselorChat from "./components/CounselorPortal/CounselorChat/CounselorChat";
import Counsel from "./components/CounselorPortal/CounselorCalendar/Counsel";
import CounselorSignin from "./components/authPages/CounselorSignin";
import { ToastContainer } from "react-toastify";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import AddJournalPage from "./components/fildHouseDashboard/Journal/AddJournalPage";
import JournalDetails from "./components/fildHouseDashboard/Journal/JournalDetails/JournalDetails";
import UserJournal from "./components/common/UserJournal/UserJournal";
import AlbumSongs from "./components/fildHouseDashboard/QuietRoom/AlbumSongs/AlbumSongs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermAndConditions";
import AboutUsPage from "./pages/AboutUsPage";
import AthleteChatRoom from "./components/fildHouseDashboard/athleteChat/AthleteChatRoom";
import PatientDemographicFormPage from "./pages/PatientDemographicFormPage";
import PatientDemographicForm from "./components/fildHouseDashboard/PatientDemographicForm/PatientDemographicForm";
import CounselorAvailability from "./components/CounselorPortal/CounselorAvailability/CounselorAvailability";
import AllSongs from "./components/fildHouseDashboard/QuietRoom/AllSongs/AllSongs";

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const codeExists = urlParams.has("code");

    if (location.pathname === "/fieldhouse-dashboard/session" && !codeExists) {
      window.location.href =
        "https://zoom.us/oauth/authorize?client_id=rUTvTeIRQfKd2Y4upHOlEw&response_type=code&redirect_uri=https%3A%2F%2Finspire.testenvapp.com%2Ffieldhouse-dashboard%2Fsession";
    }
  }, [location]);

  const isLoggedIn = () => {
    return localStorage.getItem("token") !== null;
  };

  const handleRedirect = () => {
    if (
      location.pathname == "/fieldhouse-dashboard/patient-demographic-form" &&
      !isLoggedIn()
    ) {
      localStorage.setItem(
        "redirectPath",
        "/fieldhouse-dashboard/patient-demographic-form"
      );
    }
  };

  useEffect(() => {
    handleRedirect();
  }, [location]);

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/term-and-conditions" element={<TermsConditions />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route
          path="/choose-role"
          element={
            <PrivateRoute>
              <ChooseRole />
            </PrivateRoute>
          }
        />
        <Route
          path="/sign-up"
          element={
            <PrivateRoute>
              <SignUp />
            </PrivateRoute>
          }
        />
        <Route
          path="/sign-in"
          element={
            <PrivateRoute>
              <SignIn />
            </PrivateRoute>
          }
        />
        <Route
          path="/counselor-sign-in"
          element={
            <PrivateRoute>
              <CounselorSignin />
            </PrivateRoute>
          }
        />
        <Route
          path="/otp-verification"
          element={
            <PrivateRoute>
              <OtpVerification />
            </PrivateRoute>
          }
        />
        <Route
          path="patient-demographic-form"
          element={<PatientDemographicForm />}
        />
        <Route path="/fieldhouse-dashboard" element={<FildHouseDashboard />}>
          <Route index element={<Dashboard />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="session" element={<Session />} />
          <Route path="journal" element={<Journal />} />
          <Route
            path="/fieldhouse-dashboard/journal/:id"
            element={<JournalDetails />}
          />
          <Route path="journal/add-new" element={<AddJournalPage />} />
          <Route path="journal/my-journal" element={<UserJournal />} />
          <Route path="assesment-tests" element={<AssesmentTests />} />
          <Route path="assement-questions" element={<AssesmentQuestions />} />
          <Route path="library" element={<Library />} />
          <Route path="chat" element={<AthleteChatRoom />} />
          <Route path="library/add-new" element={<AddNew />} />
          <Route path="quite-room" element={<QuiteRoomPage />} />
          <Route
            path="/fieldhouse-dashboard/album/:id"
            element={<AlbumSongs />}
          />
          <Route path="/fieldhouse-dashboard/songs" element={<AllSongs />} />
        </Route>
        <Route path="/coaching-dashboard" element={<CoachingRoomDashboard />}>
          <Route index element={<CoachDashboard />} />
          <Route path="dashboard" element={<CoachDashboard />} />
          <Route path="session" element={<CoachSessions />} />
          <Route path="journal" element={<CoachJournal />} />
          <Route
            path="/coaching-dashboard/journal/:id"
            element={<JournalDetails />}
          />
          <Route path="journal/add-new" element={<AddJournalPage />} />
          <Route path="journal/my-journal" element={<UserJournal />} />
          <Route path="chat" element={<CoachChatRoom />} />
          <Route path="library" element={<CoachLibrary />} />
        </Route>
        <Route path="/counselor-portal" element={<CounselorePortalDashboard />}>
          <Route index element={<CounselorDashboard />} />
          <Route path="dashboard" element={<CounselorDashboard />} />
          <Route path="calendar" element={<Counsel />} />
          <Route path="journal" element={<CounselorJournal />} />
          <Route
            path="counselor-availability"
            element={<CounselorAvailability />}
          />

          <Route
            path="/counselor-portal/journal/:id"
            element={<JournalDetails />}
          />
          <Route path="journal/add-new" element={<AddJournalPage />} />
          <Route path="journal/my-journal" element={<UserJournal />} />
          <Route path="chat" element={<CounselorChat />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
