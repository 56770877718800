import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import ReactQuill from "react-quill-new";
import { updateNotesofAttendee } from "../../../services/CounselingAttendeesService";

export default function CounselorCalendarModal({
  isOpen = false,
  toggle,
  onSubmit,
  submitText = "Save",
  calendars = [],
  attendees = [],
  schedule,
  startDate,
  endDate,
  currentUser,
  schedules,
  body = "",
}) {
  const [openSelectCalendars, setOpenSelectCalendars] = useState(false);
  const [openSelectAttendees, setOpenSelectAttendees] = useState(false);
  const wrapperSelectCalendarsRef = useRef(null);
  const wrapperSelectAttendeesRef = useRef(null);
  const dateRangePickerRef = useRef(null);
  const [calendarId, setCalendarId] = useState(calendars[0]?.id || "");
  const [attendeeId, setAttendeeId] = useState(attendees[0]?.id || "");
  const [selectedAttendees, setSelectedAttendees] = useState([]);
  const [title, setTitle] = useState("");
  const [note, setNote] = useState("");
  const [startDateTime, setStartDateTime] = useState(new Date());
  const [endDateTime, setEndDateTime] = useState(new Date());
  const [ticketId, setTicketId] = useState("");

  const handleClick = (e) => {
    if (wrapperSelectCalendarsRef.current?.contains(e.target)) return;
    if (wrapperSelectAttendeesRef.current?.contains(e.target)) return;
    setOpenSelectCalendars(false);
    setOpenSelectAttendees(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleClick, false);
    return () => {
      document.removeEventListener("click", handleClick, false);
    };
  }, []);
  console.log("attendees", attendees);

  // useEffect(() => {
  //   if (dateRangePickerRef.current) {
  //     if (schedule) {
  //       setCalendarId(schedule.calendarId);
  //       const selectedAttendee = attendees.find((element) =>
  //         schedule.attendees.includes(element.name)
  //       );
  //       setAttendeeId(selectedAttendee ? selectedAttendee.id : "");
  //       setTitle(schedule.title);
  //       setNote(currentUser?.body);
  //       console.log("useffect currentUser?.body", currentUser?.body);
  //       const scheduleStart = new Date(schedule.start);
  //       const scheduleEnd = new Date(schedule.end);

  //       if (isNaN(scheduleStart.getTime()) || isNaN(scheduleEnd.getTime())) {
  //         console.error(
  //           "Invalid schedule dates:",
  //           schedule.start,
  //           schedule.end
  //         );
  //       } else {
  //         setStartDateTime(scheduleStart);
  //         setEndDateTime(scheduleEnd);
  //         dateRangePickerRef.current.setStartDate(
  //           scheduleStart.toISOString().split("T")[0]
  //         );
  //       }
  //     } else {
  //       const today = new Date();
  //       setStartDateTime(startDate ? new Date(startDate) : today);
  //       setEndDateTime(endDate ? new Date(endDate) : today);
  //       dateRangePickerRef.current.setStartDate(
  //         startDate
  //           ? new Date(startDate).toISOString().split("T")[0]
  //           : today.toISOString().split("T")[0]
  //       );
  //     }
  //   }

  //   if (modalSchedule && modalSchedule.length > 0) {
  //     const firstSchedule = modalSchedule[0];
  //     const start = new Date(firstSchedule.start);
  //     const end = new Date(firstSchedule.end);

  //     if (!isNaN(start.getTime()) && !isNaN(end.getTime())) {
  //       setStartDateTime(start);
  //       setEndDateTime(end);
  //     }
  //   }
  // }, [schedule, startDate, endDate, attendees, modalSchedule]);

  function reset() {
    const today = new Date();
    setCalendarId(calendars[0]?.id || "");
    setAttendeeId(attendees[0]?.id || "");
    setTitle(currentUser?.name?.[0]);
    setNote(currentUser?.body);
    setStartDateTime(today);
    setEndDateTime(today);
    if (dateRangePickerRef.current) {
      dateRangePickerRef.current.setStartDate(
        today.toISOString().split("T")[0]
      );
    }
  }

  const resetInputs = () => {
    const today = new Date();
    setCalendarId(calendars[0]?.id || "");
    setSelectedAttendees([]);
    setTitle(currentUser?.name?.[0]);
    setNote(currentUser?.body);
    setStartDateTime(today);
    setEndDateTime(today);
    if (dateRangePickerRef.current) {
      dateRangePickerRef.current.setStartDate(
        today.toISOString().split("T")[0]
      );
    }
  };

  const handleAttendeeRemove = (indexToRemove) => {
    setSelectedAttendees((prevSelectedAttendees) =>
      prevSelectedAttendees.filter((_, index) => index !== indexToRemove)
    );
  };

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline"],
      [{ align: [] }],
      ["link"],
      ["image"],
      [{ color: [] }, { background: [] }],
      ["clean"], // A button to clear the editor content
    ],
  };

  useEffect(() => {
    setTicketId(currentUser?.id);
    setNote(currentUser?.body);
  }, [currentUser]);

  console.log("currentUser", currentUser);
  console.log("currentUser?.id", currentUser?.id);
  console.log("notes", currentUser?.body);
  console.log("note", note);
  console.log("schedule?.notes", schedules);

  const saveNotes = async () => {
    const ticketId = currentUser?.id;
    if (ticketId) {
      await updateNotesofAttendee(ticketId, note);
    }
  };

  return (
    <>
      <Modal
        show={isOpen}
        onHide={() => {
          toggle();
          reset();
        }}
        centered
      >
        <div className="tui-full-calendar-popup-container">
          {/* Title */}
          <div className="tui-full-calendar-popup-section pt-4 ff-gotham-bold">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              id="title"
              className="form-control custom-title-text"
              placeholder="Event title"
              value={currentUser?.name?.[0]}
              readOnly
            />
          </div>
          {/* Note */}
          <div className="tui-full-calendar-popup-section mt-4">
            <ReactQuill
              key={note ? "loaded" : "initial"}
              id="note"
              className="form-control"
              placeholder="Counseling Notes"
              value={note}
              onChange={setNote}
              modules={modules}
              theme="snow"
            />
          </div>
          {/* Buttons */}
          <div className="tui-full-calendar-popup-footer mt-4 d-flex align-items-center justify-content-end gap-2">
            <button
              style={{ height: "42px" }}
              className="bg_theme border-0 px-4 rounded-2 text-white"
              onClick={() => {
                onSubmit({
                  calendarId,
                  title,
                  body: note,
                  start: startDateTime.toISOString(),
                  end: endDateTime.toISOString(),
                });
                toggle();
                resetInputs();
                saveNotes();
              }}
            >
              Save Notes
            </button>
            <button
              style={{ background: "#0071BD", height: "42px" }}
              className="border-0 px-4 rounded-2 text-white"
              onClick={() => {
                toggle();
                resetInputs();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
