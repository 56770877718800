import React, { useState, useEffect } from "react";
import "./WhatsNew.css";
import { Typography } from "antd";
import { Layout } from "antd";
import { Button, Card, Col, Row } from "react-bootstrap";
import { getAllAlbums } from "../../../../services/AllAlbumsService";
import { Link } from "react-router-dom";

const WhatsNew = () => {
  const { Title, Text } = Typography;
  const [data, setData] = useState([]);

  // Fetch albums when the component mounts
  useEffect(() => {
    const fetchAlbums = async () => {
      const albums = await getAllAlbums();
      setData(albums);
    };

    fetchAlbums();
  }, []);

  return (
    <div>
      <hr className="hr-line-quite-room border-0" />
      <Layout className="flex-row align-items-center bg-transparent">
        <Layout className="bg-transparent">
          <Title className="fs_24 ff-gotham-bold mb-0">What's New</Title>
          <Text style={{ color: "#808191" }} className="fs_18 ff-gotham-normal">
            See What’s New this week
          </Text>
        </Layout>
        <Button
          className="bg_theme border-0 ff-gotham-bold"
          style={{ width: "111px", height: "44px", borderRadius: "10px" }}
        >
          See All
        </Button>
      </Layout>
      <Row className="bg-transparent">
        {data.map((album, index) => (
          <Col className="mt-4 pt-2" key={index} lg={6}>
            <Link to={`/fieldhouse-dashboard/album/${album?._id}`}>
              <Card className="border-0 bg-transparent">
                <Layout className="bg-transparent">
                  <img
                    style={{ width: "300px", height: "280px" }}
                    className="w-100 border-0 object-fit-cover album-image"
                    src={album.coverImage}
                    alt={album.name}
                  />
                </Layout>
                <Layout className="bg-transparent">
                  <Title className="ff-gotham-bold fs_20 mt-2 mb-0">
                    {album.name}
                  </Title>
                  <Text
                    style={{ color: "#808191" }}
                    className="fs_15 ff-gotham-normal"
                  >
                    {album.genre}
                  </Text>
                </Layout>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default WhatsNew;
