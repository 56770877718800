import "./Sessions.css";
import React, { useEffect, useState } from "react";
import { Button, Card, Image } from "antd";
import { Typography } from "antd";
import laptopWithClock from "../../../../assets/image/png/watchOnLaptop.png";
import { Col, Row } from "react-bootstrap";

const Sessions = ({ userSessions }) => {
  const [activeTab, setActiveTab] = useState("All");
  const { Text } = Typography;

  const handleButtonClick = (tab) => {
    setActiveTab(tab);
  };
  const formattedDate = (date) => {
    const structuredDate = new Date(date).toISOString().split("T")[0];
    return structuredDate;
  };
  return (
    <div className="pb-5 pt-4">
      <div className="d-flex align-items-center gap-4 mt-4">
        <Button
          className={`btn-sessions-tab w-100 fs-15 ${
            activeTab === "All"
              ? "btn-sessions-tab-active ff-gotham-bold"
              : "ff-gotham-normal"
          }`}
          onClick={() => handleButtonClick("All")}
        >
          All
        </Button>
        <Button
          className={`btn-sessions-tab w-100 fs-15 ${
            activeTab === "Recent"
              ? "btn-sessions-tab-active ff-gotham-bold"
              : "ff-gotham-normal"
          }`}
          onClick={() => handleButtonClick("Recent")}
        >
          Recent
        </Button>
        <Button
          className={`btn-sessions-tab w-100 fs-15 ${
            activeTab === "Upcoming"
              ? "btn-sessions-tab-active ff-gotham-bold"
              : "ff-gotham-normal"
          }`}
          onClick={() => handleButtonClick("Upcoming")}
        >
          Upcoming
        </Button>
      </div>
      <Row className="mt-4 pt-3">
        {userSessions && userSessions.length > 0 ? (
          userSessions.map((value, index) => (
            <Col lg={3} key={index}>
              <Card
                bodyStyle={{ padding: 0 }}
                style={{ borderRadius: "10px", overflow: "hidden" }}
              >
                <div
                  style={{
                    background: "#F1F1F1",
                    borderRadius: "10px 10px 0px 0px",
                  }}
                  className="p-5 text-center position-relative"
                >
                  <Image
                    style={{ height: "97.5px", width: "97.5px" }}
                    src={laptopWithClock}
                    alt="clock"
                  />
                  <div
                    style={{ bottom: "4px", left: "0" }}
                    className="mt-3 d-flex align-items-center justify-content-between w-100 px-2 position-absolute"
                  >
                    <div className="d-flex align-items-center gap-2">
                      <div
                        style={{ marginTop: "1px" }}
                        className="blue-dot"
                      ></div>
                      <Text
                        style={{
                          color: "#808191",
                        }}
                        className="fs_12 mb-0 ff-inter fw-medium"
                      >
                        {value?.time}
                      </Text>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <div
                        style={{ marginTop: "1px" }}
                        className="green-dot"
                      ></div>
                      <Text
                        style={{
                          color: "#808191",
                        }}
                        className="fs_12 ff-inter fw-medium"
                      >
                        {formattedDate(value?.date)}
                      </Text>
                    </div>
                  </div>
                </div>
                <div className="p-3">
                  <div className="d-flex align-items-center gap-2 mt-3">
                    <div className="name-bg d-flex align-items-center justify-content-center">
                      <p className="mb-0 fw-bold fs_12 ff-gotham-normal">
                        {value?.athleteName?.charAt(0).toUpperCase()}
                      </p>
                    </div>
                    <p className="mb-0 ff-gotham-bold fs_12">
                      {value?.athleteName}
                    </p>
                  </div>
                </div>
              </Card>
            </Col>
          ))
        ) : (
          <Col className="text-center">
            <Text
              style={{
                color: "#808191",
              }}
              className="fs_16 ff-gotham-medium"
            >
              No sessions booked
            </Text>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Sessions;
