import React, { useEffect, useState } from "react";
import "../fildHouseDashboard/fildhouse.css";
import noti_icon from "../../assets/image/svg/Group 1000002848.svg";
import coachNotificationIcon from "../../assets/image/svg/coachNotificationIcon.svg";
import search_icon from "../../assets/image/svg/search.svg";

const Header = ({ onToggleSidebar }) => {
  const [userRole, setUserRole] = useState("");
  useEffect(() => {
    const userData = localStorage.getItem("userData");
    try {
      const parsedData = JSON.parse(userData);
      setUserRole(parsedData.role);
    } catch (error) {
      console.error("Error parsing userData:", error);
    }
  }, []);
  const notificationIcon =
    userRole === "Coach" ? coachNotificationIcon : noti_icon;

  return (
    <div className="bg-white py-4 ps-2 border-start border-start-1 pb-3 border-bottom border-bottom-2">
      <div className="px-4 justify-content-between align-items-center d-flex">
        <div className="d-flex align-items-center gap-3">
          <div
            onClick={onToggleSidebar}
            style={{ cursor: "pointer" }}
            className="navmanu"
          >
            <div className="navdot"></div>
            <div className="navdot my-1"></div>
            <div className="navdot"></div>
          </div>
          <div className="d-flex px-3 align-items-center gap-3 custom_input">
            <img
              style={{ cursor: "pointer" }}
              src={search_icon}
              alt="search_icon"
            />
            <input className="w-100 py-2" type="text" placeholder="Search" />
          </div>
        </div>
        <img
          style={{ cursor: "pointer" }}
          src={notificationIcon}
          alt="notification_icon"
        />
      </div>
    </div>
  );
};

export default Header;
