import React, { useState } from "react";
import "./Pricing.css";
import { Button, Col, Container, Row } from "react-bootstrap";
import nextArrow from "../../../assets/image/png/nextArrow.png";
import Toggle from "react-styled-toggle";
import tick from "../../../assets/image/png/tick.png";
import blackTick from "../../../assets/image/png/blackTick.png";

const Pricing = () => {
  const [checked, setChecked] = useState(true);
  const [data, setData] = useState([
    {
      plan: "Professional Plan",
      for: "For Individuals and Small Team",
      price: "$15",
      jobs: "Up to 15 Jobs per Account",
      candidates: "Up to 10,000 Candidates",
      hiring: "Unlimited Hiring Managers",
    },
    {
      plan: "Enterprise Plan",
      for: "For Industry Leaders",
      price: "$35",
      jobs: "Unlimited Jobs",
      candidates: "Unlimited Candidates",
      hiring: "Unlimited Hiring Managers",
    },
    {
      plan: "Custom Plan",
      for: "For Custom Plan",
      price: "On Demand",
      jobs: "Everything in Enterprise plan",
      candidates: "Custom Features",
      hiring: "Custom Integrations",
    },
  ]);
  return (
    <div id="Pricing" className="bg-white py-5">
      <Container>
        <div className="d-flex align-items-center gap-4 justify-content-center">
          <hr className="horizontal-line" />
          <h3 className="ff-gotham-normal clr-black fs_20 mb-0">Pricing</h3>
          <hr className="horizontal-line" />
        </div>
        <h2 className="mb-0 ff-gotham-bold fs-56 text-center mt-3">
          <span className="elevate-text">Simple</span> and Transparent Pricing
        </h2>
        <div className="d-flex align-items-center gap-4 justify-content-center mt-5">
          <p className="ff-gotham-normal fs_20 clr-black mb-0">Yearly plans</p>
          <Toggle
            onChange={() => setChecked(!checked)}
            checked={checked}
            backgroundColorChecked="#0071BD"
            backgroundColorUnchecked="#0071BD"
          />
          <p className="ff-gotham-normal fs_20 clr-black mb-0">Monthly plans</p>
        </div>
        <Row className="mt-5">
          {data.map((values, index) => (
            <Col className="mt-4" lg={4} md={6} key={index}>
              <div
                className={`${
                  index === 1 ? "bg-blue text-white" : "clr-black"
                } p-4 border-20`}
              >
                <p className="ff-gotham-bold fs_20">{values.plan}</p>
                <p className="ff-gotham-normal fs_20">{values.for}</p>
                <p className="ff-poppins fw-normal fs_20 ">
                  <span className="fw-medium fs-32">{values.price}</span>
                  <span className={index === 2 ? "d-none" : ""}>
                    /per user per month
                  </span>
                </p>
                <Button
                  style={{
                    maxWidth: "320px",
                    height: "45px",
                    borderRadius: "10px",
                  }}
                  className={`${
                    index === 1 ? "border-white" : ""
                  } btn-green-common w-100 d-flex align-items-center mt-4 gap-2 justify-content-center`}
                >
                  Start 14-day Free Trial
                  <img
                    style={{ width: "13px", height: "10px" }}
                    src={nextArrow}
                    alt="nextArrow"
                  />
                </Button>
                <hr
                  className={`${
                    index === 1 ? "horizontal-line-blue" : "horizontal-line"
                  } w-100 mt-4`}
                />
                <div className="d-flex align-items-center gap-3 mt-3">
                  <div
                    className={`${
                      index === 1 ? "white-circle" : "black-circle"
                    } d-flex align-items-center justify-content-center`}
                  >
                    {index === 1 ? (
                      <img
                        style={{ width: "5px", height: "4px" }}
                        src={blackTick}
                        alt="tick"
                      />
                    ) : (
                      <img
                        style={{ width: "5px", height: "4px" }}
                        src={tick}
                        alt="tick"
                      />
                    )}
                  </div>
                  <p className="ff-gotham-normal mb-0 fs_16">{values.jobs}</p>
                </div>
                <div className="d-flex align-items-center gap-3 mt-3">
                  <div
                    className={`${
                      index === 1 ? "white-circle" : "black-circle"
                    } d-flex align-items-center justify-content-center`}
                  >
                    {index === 1 ? (
                      <img
                        style={{ width: "5px", height: "4px" }}
                        src={blackTick}
                        alt="tick"
                      />
                    ) : (
                      <img
                        style={{ width: "5px", height: "4px" }}
                        src={tick}
                        alt="tick"
                      />
                    )}
                  </div>
                  <p className="ff-gotham-normal mb-0 fs_16">{values.jobs}</p>
                </div>
                <div className="d-flex align-items-center gap-3 mt-3">
                  <div
                    className={`${
                      index === 1 ? "white-circle" : "black-circle"
                    } d-flex align-items-center justify-content-center`}
                  >
                    {index === 1 ? (
                      <img
                        style={{ width: "5px", height: "4px" }}
                        src={blackTick}
                        alt="tick"
                      />
                    ) : (
                      <img
                        style={{ width: "5px", height: "4px" }}
                        src={tick}
                        alt="tick"
                      />
                    )}
                  </div>
                  <p className="ff-gotham-normal mb-0 fs_16">{values.hiring}</p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Pricing;
