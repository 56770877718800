import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export const getAllAlbums = async () => {
  try {
    const res = await axios({
      method: "get",
      url: `${API_URL}/albums`,
    });
    return res?.data; // Return the album data from the API
  } catch (error) {
    console.error("Error fetching albums:", error);
    return [];
  }
};

export const getAlbumsSongs = async (id) => {
  try {
    const res = await axios({
      method: "get",
      url: `${API_URL}/albums/${id}/songs`,
    });
    return res?.data; // Return the album data from the API
  } catch (error) {
    console.error("Error fetching albums:", error);
    return [];
  }
};
