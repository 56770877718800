import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import { Container, Col } from "react-bootstrap";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "../components/fildHouseDashboard/Header";
import logo from "../assets/image/svg/logo.svg";
import dashboard_icon from "../assets/image/png/timer_icon.png";
import dashboard_icon_unselected from "../assets/image/png/dashboardunselected.png";
import calendar from "../assets/image/png/calendar.png";
import blackCalendar from "../assets/image/png/blackCalendar.png";
import BlacklibraryIcon from "../assets/image/png/BlacklibraryIcon.png";
import whiteLibraryicon from "../assets/image/png/whiteLibraryicon.png";
import journalSelected from "../assets/image/png/journalSelected.png";
import journalUnselected from "../assets/image/png/journalIcon.png";
import chatSelected from "../assets/image/png/chaticonwhite.png";
import chatUnselected from "../assets/image/png/chaticonblack.png";
import arrowRight from "../assets/image/svg/arrowRight.svg";
import logoIcon from "../assets/image/svg/logoIcon.svg";
import plusIcon from "../assets/image/png/bluePlus.png";

const { Content } = Layout;

const CoachingRoomDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selected, setSelected] = useState(0);
  const [hideSidebar, setHideSidebar] = useState(false);
  const [userName, setUserName] = useState("");
  const [userInitial, setUserInitial] = useState("");
  const [showProfileBox, setShowProfileBox] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState({
    journal: false,
  });

  const menuItems = [
    {
      name: "Dashboard",
      link: "/dashboard",
      selectedImage: dashboard_icon,
      unselectedImage: dashboard_icon_unselected,
    },
    {
      name: "Session",
      link: "/session",
      selectedImage: calendar,
      unselectedImage: blackCalendar,
    },
    {
      name: "Journal",
      link: "/journal",
      selectedImage: journalSelected,
      unselectedImage: journalUnselected,
      dropdown: [
        { name: "Add New", link: "journal/add-new", selectedImage: plusIcon },
        {
          name: "My journal",
          link: "journal/my-journal",
          selectedImage: plusIcon,
        },
      ],
    },
    {
      name: "Library",
      link: "/library",
      selectedImage: whiteLibraryicon,
      unselectedImage: BlacklibraryIcon,
    },
    {
      name: "Chat",
      link: "/chat",
      selectedImage: chatSelected,
      unselectedImage: chatUnselected,
    },
  ];

  const handleClick = (link, index) => {
    navigate(`/coaching-dashboard${link}`);
    setSelected(index);

    if (menuItems[index].name === "Journal") {
      setDropdownVisible((prevState) => ({
        ...prevState,
        journal: !prevState.journal,
      }));
    } else {
      setDropdownVisible({ journal: false });
    }
  };
  const toggleSidebar = () => {
    setHideSidebar((prevState) => !prevState);
  };

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      try {
        const parsedData = JSON.parse(userData);
        setUserName(parsedData.name);
        setUserInitial(parsedData.name.charAt(0));
      } catch (error) {
        console.error("Error parsing userData:", error);
      }
    }
  }, []);

  useEffect(() => {
    const path = location.pathname.replace("/coaching-dashboard", "");

    const activeTab = menuItems.findIndex(
      (item) =>
        item.link === path ||
        (item.name === "Journal" && path.startsWith("/journal")) ||
        (item.dropdown &&
          item.dropdown.some((dropdownItem) => dropdownItem.link === path))
    );

    if (activeTab !== -1) {
      setSelected((prevSelected) => {
        if (prevSelected !== activeTab) {
          if (menuItems[activeTab].name === "Journal") {
            setDropdownVisible((prevState) => ({
              ...prevState,
              journal: true,
            }));
          } else {
            setDropdownVisible((prevState) => ({
              ...prevState,
              journal: false,
            }));
          }
          return activeTab;
        }
        return prevSelected;
      });
    } else {
      setDropdownVisible((prevState) => ({ ...prevState, journal: false }));
    }
  }, [location.pathname, menuItems]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const userData = localStorage.getItem("userData");
    if (!token) {
      navigate("/");
    }
    try {
      const parsedData = JSON.parse(userData);
      const userRole = parsedData.role;
      console.log(userRole);
      if (userRole !== "Coach") {
        navigate("/");
      }
    } catch (error) {
      console.error("Error parsing userData:", error);
    }
  }, [navigate]);

  const handleuserLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    navigate("/");
  };

  return (
    <div className={`min-vh-100`}>
      <Container fluid className="px-0 overflow-hidden">
        <Layout>
          <div
            className={`row min-vh-100 ${
              hideSidebar === true ? "padding-left-102" : ""
            }`}
          >
            <Col
              lg={2}
              className={`d-none border-end border-end-1 pe-0 h-100 d-lg-block mb-4 mb-md-0`}
            >
              <div
                style={{ height: "100vh", left: 0, top: 0 }}
                className={`card d-flex z-1 ${
                  hideSidebar === true ? "" : "col-2"
                } justify-content-between rounded-0 position-fixed border-0 custom_space`}
              >
                {/* Sidebar content */}
                <div>
                  <div className="border-bottom-1 d-flex justify-content-center border-bottom">
                    {hideSidebar === true ? (
                      <img
                        onClick={() => navigate("/")}
                        width={43.6}
                        className="pt-3 pb-3"
                        style={{ cursor: "pointer" }}
                        src={logoIcon}
                        alt="logo"
                      />
                    ) : (
                      <img
                        onClick={() => navigate("/")}
                        width={161}
                        className="pt-3 pb-3"
                        style={{ cursor: "pointer" }}
                        src={logo}
                        alt="logo"
                      />
                    )}
                  </div>
                  {/* Sidebar Menu */}
                  <div className="mt-4">
                    {menuItems.map((item, index) => {
                      const isActive = selected === index;
                      const imageSrc = isActive
                        ? item.selectedImage
                        : item.unselectedImage;

                      return (
                        <div
                          style={{ cursor: "pointer" }}
                          key={index}
                          className={`${
                            isActive
                              ? "border_custom_coachingroom"
                              : "border_custom_coachingroom_unselected"
                          }`}
                          onClick={() => handleClick(item.link, index)}
                        >
                          <div
                            className={`p-3 d-flex align-items-center position-relative gap-3 ${
                              hideSidebar === true ? "mx-3" : "mx-4"
                            } menu-item space_grotesk ${
                              isActive
                                ? "bg_blue rounded-3 text-white"
                                : "text-black"
                            }`}
                          >
                            {item.selectedImage && item.unselectedImage && (
                              <img
                                style={{ width: "22px" }}
                                src={imageSrc}
                                alt={`${item.name} icon`}
                              />
                            )}
                            <p
                              className={`mb-0 fs_15 ${
                                isActive
                                  ? "text-white ff-gotham-bold"
                                  : "text-black ff-gotham-normal"
                              } ${hideSidebar === true ? "d-none" : "d-block"}`}
                            >
                              {item.name}
                            </p>
                          </div>
                          {item.name === "Journal" &&
                          dropdownVisible.journal ? (
                            <div
                              className={` ${
                                hideSidebar === true
                                  ? "dropdown-menu-div-small-coach"
                                  : "dropdown-menu-div-coach"
                              }`}
                            >
                              {item.dropdown &&
                                item.dropdown.map((dropdownItem, idx) => (
                                  <button
                                    key={idx}
                                    className={`dropdown-item ${
                                      hideSidebar === true
                                        ? "px-0  justify-content-center "
                                        : "ps-3"
                                    } d-flex align-items-center gap-3`}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      navigate(dropdownItem.link);
                                    }}
                                  >
                                    <img
                                      className={` ${
                                        hideSidebar === true ? "py-2" : "py-0"
                                      }`}
                                      style={{ width: "18px" }}
                                      src={dropdownItem.selectedImage}
                                      alt="dropdown icon"
                                    />
                                    <p
                                      className={`mb-0 py-2 fs_14 ff-gotham-normal ${
                                        hideSidebar === true
                                          ? "d-none"
                                          : "d-block"
                                      }`}
                                    >
                                      {dropdownItem.name}
                                    </p>
                                  </button>
                                ))}
                            </div>
                          ) : null}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  onClick={() => setShowProfileBox(!showProfileBox)}
                  style={{ borderTop: "0.6px solid #E0E0E0" }}
                >
                  <div className="d-flex align-items-center cursor-pointer justify-content-center gap-3 py-3 position-relative">
                    <div
                      style={{
                        width: "44px",
                        height: "44px",
                        borderRadius: "100%",
                        backgroundColor: " #00000033",
                      }}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <p
                        style={{ color: "#404040" }}
                        className="mb-0 ff-gotham-bold fs_18"
                      >
                        {userInitial}
                      </p>
                    </div>
                    <div
                      className={`${
                        hideSidebar === true ? "d-none" : "d-flex"
                      } align-items-center gap-3`}
                    >
                      <div>
                        <p className="mb-0 ff-gotham-bold">{userName}</p>
                        <p className="mb-0 ff-gotham-normal">Coach</p>
                      </div>
                      <div>
                        <button className="arrow-circle bg-transparent d-flex align-items-center justify-content-center">
                          <img src={arrowRight} alt="" />
                        </button>
                      </div>
                    </div>
                    <div
                      className={`position-absolute p-3 bg-white profile-dropdown ${
                        showProfileBox === true ? "d-block" : "d-none"
                      }`}
                    >
                      <ul className="m-0 p-0">
                        <li>
                          <button
                            onClick={handleuserLogout}
                            className="border-0 bg-transparent ff-gotham-medium fs_14"
                          >
                            Logout
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col
              style={{ background: "#F4F8FA", minHeight: "100vh" }}
              xs={12}
              lg={hideSidebar === true ? 12 : 10}
              className="position-relative ps-lg-0"
            >
              <div
                className={`position-sticky top-0 z_index ${
                  hideSidebar === true ? null : "header-custom-margin"
                }`}
              >
                <Header onToggleSidebar={toggleSidebar} />
              </div>
              <div className="ps-2">
                <Content className="ps-4 pe-4 ">
                  <Outlet />
                </Content>
              </div>
            </Col>
          </div>
        </Layout>
      </Container>
    </div>
  );
};

export default CoachingRoomDashboard;
