import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import { Container, Col, Image } from "react-bootstrap";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "../components/fildHouseDashboard/Header";
import logo from "../assets/image/svg/logo.svg";
import light from "../assets/image/png/Light.png";
import dashboard_icon from "../assets/image/png/timer_icon.png";
import dashboard_icon_unselected from "../assets/image/png/dashboardunselected.png";
import calendar from "../assets/image/png/calendar.png";
import blackCalendar from "../assets/image/png/blackCalendar.png";
import BlacklibraryIcon from "../assets/image/png/BlacklibraryIcon.png";
import whiteLibraryicon from "../assets/image/png/whiteLibraryicon.png";
import plusIcon from "../assets/image/png/plusIcon.png";
import journalSelected from "../assets/image/png/journalSelected.png";
import journalUnselected from "../assets/image/png/journalIcon.png";
import assesmentSelected from "../assets/image/png/assesmentSelected.png";
import assesmentUnselected from "../assets/image/png/assesmentunSelected.png";
import whiteQuiteRoomIcon from "../assets/image/png/whiteQuiteRoomIcon.png";
import chatSelected from "../assets/image/png/chaticonwhite.png";
import chatUnselected from "../assets/image/png/chaticonblack.png";
import arrowRight from "../assets/image/svg/arrowRight.svg";
import logoIcon from "../assets/image/svg/logoIcon.svg";

const { Content } = Layout;

const FildHouseDashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selected, setSelected] = useState(0);
  const [hideSidebar, setHideSidebar] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState({
    journal: false,
    library: false,
  });
  const [userName, setUserName] = useState("");
  const [userInitial, setUserInitial] = useState("");
  const [showProfileBox, setShowProfileBox] = useState(false);

  const menuItems = [
    {
      name: "Dashboard",
      link: "dashboard",
      selectedImage: dashboard_icon,
      unselectedImage: dashboard_icon_unselected,
    },
    {
      name: "Session",
      link: "session",
      selectedImage: calendar,
      unselectedImage: blackCalendar,
    },
    {
      name: "Journal",
      link: "journal",
      selectedImage: journalSelected,
      unselectedImage: journalUnselected,
      dropdown: [
        { name: "Add New", link: "journal/add-new", selectedImage: plusIcon },
        {
          name: "My journal",
          link: "journal/my-journal",
          selectedImage: plusIcon,
        },
      ],
    },
    {
      name: "Library",
      link: "library",
      selectedImage: whiteLibraryicon,
      unselectedImage: BlacklibraryIcon,
      dropdown: [
        { name: "Add New", link: "library/add-new", selectedImage: plusIcon },
      ],
    },
    {
      name: "Assesment Tests",
      link: "assesment-tests",
      selectedImage: assesmentSelected,
      unselectedImage: assesmentUnselected,
    },
    {
      name: "Chat",
      link: "chat",
      selectedImage: chatSelected,
      unselectedImage: chatUnselected,
    },
  ];

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath === "/fieldhouse-dashboard/quite-room") {
      setSelected(-1);
      setDropdownVisible({ journal: false, library: false });
    } else {
      const matchedIndex = menuItems.findIndex((item) => {
        if (currentPath.endsWith(item.link)) return true;
        if (currentPath.startsWith("/fieldhouse-dashboard/assement-questions"))
          return item.link === "assesment-tests";
        if (currentPath.startsWith("/fieldhouse-dashboard/library/add-new"))
          return item.link === "library";
        if (currentPath.startsWith("/fieldhouse-dashboard/journal/add-new"))
          return item.link === "journal";
        if (currentPath.startsWith("/fieldhouse-dashboard/journal/")) {
          const parts = currentPath.split("/");
          if (parts.length === 4 && parts[2] === "journal" && parts[3]) {
            return item.link === "journal";
          }
        }
        return false;
      });
      setSelected(matchedIndex !== -1 ? matchedIndex : 0);
      setDropdownVisible({
        journal: matchedIndex === 2,
        library: matchedIndex === 3,
      });
    }
  }, [location.pathname]);

  const handleClick = (link, index) => {
    const itemName = menuItems[index].name.toLowerCase();
    setDropdownVisible((prev) => ({
      ...prev,
      [itemName]: !prev[itemName],
    }));
    navigate(link);
    setSelected(index);
  };

  const toggleSidebar = () => {
    setHideSidebar((prevState) => !prevState);
  };

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      try {
        const parsedData = JSON.parse(userData);
        setUserName(parsedData.name);
        setUserInitial(parsedData.name.charAt(0));
      } catch (error) {
        console.error("Error parsing userData:", error);
      }
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const userData = localStorage.getItem("userData");
    if (!token) {
      navigate("/");
    }
    try {
      const parsedData = JSON.parse(userData);
      const userRole = parsedData.role;
      if (userRole !== "Athlete") {
        navigate("/");
      }
    } catch (error) {
      console.error("Error parsing userData:", error);
    }
  }, [navigate]);

  const handleuserLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    navigate("/");
  };

  return (
    <div className="min-vh-100">
      <Container fluid className="px-0 overflow-hidden">
        <Layout>
          <div
            className={`row min-vh-100 ${
              hideSidebar === true ? "padding-left-102" : ""
            }`}
          >
            <Col
              lg={2}
              className="d-none border-end border-end-1 pe-0 h-100 d-lg-block mb-4 mb-md-0"
            >
              <div
                style={{ height: "100vh", left: 0, top: 0 }}
                className={`card d-flex z-1  field-dashboard-sidebar ${
                  hideSidebar === true ? "" : "col-2"
                } justify-content-between rounded-0 position-fixed border-0 custom_space`}
              >
                <div>
                  <div className="border-bottom-1 d-flex justify-content-center border-bottom">
                    {hideSidebar === true ? (
                      <img
                        onClick={() => navigate("/")}
                        width={43.6}
                        className="pt-3 pb-3"
                        style={{ cursor: "pointer" }}
                        src={logoIcon}
                        alt="logo"
                      />
                    ) : (
                      <img
                        onClick={() => navigate("/")}
                        width={160}
                        className="pt-3 pb-3"
                        style={{ cursor: "pointer" }}
                        src={logo}
                        alt="logo"
                      />
                    )}
                  </div>
                  {/* Sidebar Menu */}
                  <div className="mt-4">
                    {menuItems.map((item, index) => {
                      const isActive = selected === index;
                      const imageSrc = isActive
                        ? item.selectedImage
                        : item.unselectedImage;

                      return (
                        <div
                          style={{ cursor: "pointer" }}
                          key={index}
                          className={`${
                            isActive
                              ? "border_custom_fieldhouse"
                              : "border_custom_fieldhouse_unselected"
                          }`}
                          onClick={() => handleClick(item.link, index)}
                        >
                          <div
                            className={`py-3  d-flex align-items-center position-relative gap-3 ${
                              hideSidebar === true
                                ? "mx-3 px-3"
                                : "ms-3 me-4 pe-2 ps-3"
                            } menu-item space_grotesk ${
                              isActive
                                ? "bg_theme rounded-3 text-white"
                                : "text-black"
                            }`}
                          >
                            {item.selectedImage && item.unselectedImage && (
                              <img
                                style={{ width: "22px" }}
                                src={imageSrc}
                                alt={`${item.name} icon`}
                              />
                            )}
                            <p
                              className={`mb-0 fs_15 ${
                                isActive
                                  ? "text-white ff-gotham-bold"
                                  : "text-black ff-gotham-normal"
                              } ${hideSidebar === true ? "d-none" : "d-block"}`}
                            >
                              {item.name}
                            </p>
                          </div>

                          {(item.name === "Journal" &&
                            dropdownVisible.journal) ||
                          (item.name === "Library" &&
                            dropdownVisible.library) ? (
                            <div
                              className={` ${
                                hideSidebar === true
                                  ? "dropdown-menu-div-small"
                                  : "dropdown-menu-div"
                              }`}
                            >
                              {item.dropdown &&
                                item.dropdown.map((dropdownItem, idx) => (
                                  <button
                                    key={idx}
                                    className={`dropdown-item ${
                                      hideSidebar === true
                                        ? "px-0  justify-content-center "
                                        : "ps-3"
                                    } d-flex align-items-center gap-3`}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      navigate(dropdownItem.link);
                                    }}
                                  >
                                    <img
                                      className={` ${
                                        hideSidebar === true ? "py-2" : "py-0"
                                      }`}
                                      style={{ width: "18px" }}
                                      src={dropdownItem.selectedImage}
                                      alt="dropdown icon"
                                    />
                                    <p
                                      className={`mb-0 py-2 fs_14 ff-gotham-normal ${
                                        hideSidebar === true
                                          ? "d-none"
                                          : "d-block"
                                      }`}
                                    >
                                      {dropdownItem.name}
                                    </p>
                                  </button>
                                ))}
                            </div>
                          ) : null}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div>
                  <Layout
                    onClick={() => navigate("/fieldhouse-dashboard/quite-room")}
                    className="py-3 text-center cursor-pointer"
                    style={{
                      background:
                        location.pathname === "/fieldhouse-dashboard/quite-room"
                          ? "#009345"
                          : "#F4F8F9",
                    }}
                  >
                    {location.pathname ===
                    "/fieldhouse-dashboard/quite-room" ? (
                      <Image
                        style={{
                          width: hideSidebar ? "40px" : "55px",
                          height: hideSidebar ? "40px" : "54px",
                        }}
                        className="mx-auto"
                        src={whiteQuiteRoomIcon}
                        alt="Decoration"
                      />
                    ) : (
                      <Image
                        style={{
                          width: hideSidebar ? "40px" : "55px",
                          height: hideSidebar ? "40px" : "54px",
                        }}
                        className="mx-auto"
                        src={light}
                        alt="Decoration"
                      />
                    )}

                    <p
                      className={`mb-0 ff-gotham-bold ${
                        location.pathname === "/fieldhouse-dashboard/quite-room"
                          ? "text-white"
                          : ""
                      } ${hideSidebar === true ? "fs_10" : "fs_14"}`}
                    >
                      Quiet Room
                    </p>
                  </Layout>
                  <div
                    onClick={() => setShowProfileBox(!showProfileBox)}
                    className="d-flex cursor-pointer bg-transparent border-0 mx-auto position-relative align-items-center justify-content-center gap-3 py-3 "
                  >
                    <button
                      style={{
                        width: "44px",
                        height: "44px",
                        borderRadius: "100%",
                        backgroundColor: " #00000033",
                      }}
                      className="d-flex align-items-center justify-content-center border-0"
                    >
                      <p
                        style={{ color: "#404040" }}
                        className="mb-0 ff-gotham-bold fs_18"
                      >
                        {userInitial}
                      </p>
                    </button>
                    <div
                      className={`${
                        hideSidebar === true ? "d-none" : "d-flex"
                      } align-items-center gap-3`}
                    >
                      <div>
                        <p className="mb-0 ff-gotham-bold">{userName}</p>
                        <p className="mb-0 ff-gotham-normal">Athlete</p>
                      </div>
                      <div>
                        <button
                          onClick={() => setShowProfileBox(!showProfileBox)}
                          className="arrow-circle bg-transparent d-flex align-items-center justify-content-center"
                        >
                          <img src={arrowRight} alt="" />
                        </button>
                      </div>
                    </div>
                    <div
                      className={`position-absolute p-3 bg-white profile-dropdown ${
                        showProfileBox === true ? "d-block" : "d-none"
                      } ${
                        hideSidebar === true
                          ? "profile-dropdown-2"
                          : "profile-dropdown"
                      }`}
                    >
                      <ul className="m-0 p-0">
                        <li>
                          <button
                            onClick={handleuserLogout}
                            className="border-0 bg-transparent ff-gotham-medium fs_14"
                          >
                            Logout
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col
              style={{ background: "#F4F8FA", minHeight: "100vh" }}
              xs={12}
              lg={hideSidebar === true ? 12 : 10}
              className="position-relative ps-lg-0"
            >
              <div
                className={`position-sticky top-0 z_index ${
                  hideSidebar === true ? null : "header-custom-margin"
                }`}
              >
                <Header onToggleSidebar={toggleSidebar} />
              </div>
              <div className="ps-2">
                <Content className="ps-4 pe-4 ">
                  <Outlet />
                </Content>
              </div>
            </Col>
          </div>
        </Layout>
      </Container>
    </div>
  );
};

export default FildHouseDashboard;
