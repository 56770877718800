import React from "react";
import "../components/landingPage/AboutUs/AboutUs.css";
import nextArrow from "../assets/image/png/nextArrow.png";
import mainImg from "../assets/image/png/girlWithRacket.png";
import { Button, Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import AppNav from "../components/landingPage/AppNavbar/AppNav";
import AppFooter from "../components/landingPage/AppFooter/AppFooter";

const AboutUsPage = () => {
  return (
    <div className="landing-page-body">
      <AppNav />
      <div id="aboutus" className="py-5">
        <Container>
          <Row className="align-items-center flex-column-reverse flex-lg-row">
            <div className="d-flex align-items-center gap-4 justify-content-center">
              <hr className="horizontal-line" />
              <h3 className="ff-gotham-normal clr-black fs_20">About Us</h3>
              <hr className="horizontal-line" />
            </div>
            <h2
              style={{ maxWidth: "800px" }}
              className="mb-0 ff-gotham-bold fs-56 text-center mx-auto mt-3"
            >
              Get to <span className="elevate-text">Know Us</span> Better
            </h2>
            <Col className="mt-3" lg={6}>
              <div>
                <p
                  style={{ opacity: "60%" }}
                  className="mb-0 mt-4 ff-gotham-normal fs_16 clr-black"
                >
                  At Inspire Mental Fitness, we realize that athletes have
                  unique mental health challenges involving high-pressure
                  situations, disappointments, self-doubt, self-criticism, and
                  sports/life balance. Many of our clients want to reach a
                  certain level of performance but at times fall short due to
                  anxiety, negative self-talk, past experiences, or lack of
                  motivation.
                </p>
                <p
                  style={{ opacity: "60%" }}
                  className="mb-0 mt-2 ff-gotham-normal fs_16 clr-black"
                >
                  Some of our athletes report feeling like they lost their edge,
                  that spark, their joy for the game. Others simply can't
                  identify what's holding them back but know they are meant for
                  more.
                </p>
                <p
                  style={{ opacity: "60%" }}
                  className="mb-0 mt-2 ff-gotham-normal fs_16 clr-black"
                >
                  Inspire Mental Fitness focuses on the whole athlete, not
                  simply their performance. It is a comprehensive way of viewing
                  the individual, whose needs and perspectives are unique.
                </p>
                <p
                  style={{ opacity: "60%" }}
                  className="mb-0 mt-2 ff-gotham-normal fs_16 clr-black"
                >
                  By addressing their needs with a person-centered approach,
                  clients find their performance, social/family dynamics, and
                  life satisfaction improve organically.
                </p>
                <p
                  style={{ opacity: "60%" }}
                  className="mb-0 mt-2 ff-gotham-normal fs_16 clr-black"
                >
                  At Inspire Mental Fitness, we believe mental resilience
                  separates the good from the great because mental strength is
                  just as crucial as physical strength. We help athletes conquer
                  self-doubt and the pressures of competition to reach their
                  full potential.
                </p>
                <p
                  style={{ opacity: "60%" }}
                  className="mb-0 mt-2 ff-gotham-normal fs_16 clr-black"
                >
                  We equip athletes, parents, and coaches with the tools and
                  support they need to overcome obstacles, build confidence, and
                  achieve their goals, and to achieve peak performance. We don't
                  just talk about mental resilience—we build it.
                </p>
                <p
                  style={{ opacity: "60%" }}
                  className="mb-0 mt-2 ff-gotham-normal fs_16 clr-black"
                >
                  Our virtual platform offers personalized mental training
                  plans, interactive counseling sessions, and a wealth of
                  resources designed specifically for athletes of all ages and
                  skill levels.
                </p>
                <p
                  style={{ opacity: "60%" }}
                  className="mb-0 mt-2 ff-gotham-normal fs_16 clr-black"
                >
                  Inspire Mental Fitness guides teen athletes, parents, and
                  coaches in overcoming mental hurdles and achieving their full
                  potential, both on and off the field.
                </p>
                <p
                  style={{ opacity: "60%" }}
                  className="mb-0 mt-2 ff-gotham-normal fs_16 clr-black"
                >
                  Inspire Mental Fitness helps athletes of all levels conquer
                  the mental game. We get that pressure, self-doubt, and
                  setbacks can hold you back. Our personalized plans and expert
                  guidance empower athletes, parents, and coaches to build
                  resilience and achieve their full potential, on and off the
                  field. Rediscover your love of the game and unleash your true
                  potential with Inspire.
                </p>
              </div>
            </Col>
            <Col lg={6}>
              <div className="text-center position-relative">
                <img className="w-75" src={mainImg} alt="boyWithFootball" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <AppFooter />
    </div>
  );
};

export default AboutUsPage;
