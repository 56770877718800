import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserJournalRequest } from "../../../redux/slice/userJournalSlice";
import { deleteJournalRequest } from "../../../redux/slice/JournalSlice";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaEye, FaPen, FaTrash } from "react-icons/fa";
import EditJournalModal from "../../Modals/EditJournalModal";
import DeleteJournalModal from "../../Modals/DeleteJournalModal";

const formatDate = (dateString) => {
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};

const UserJournal = () => {
  const dispatch = useDispatch();
  const userJournal = useSelector((state) => state.userJournal.data?.data);
  const [selectedJournal, setSelectedJournal] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false); // State for delete modal
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      try {
        const parsedData = JSON.parse(userData);
        setUserRole(parsedData.role);
      } catch (error) {
        console.error("Error parsing userData:", error);
      }
    }
  }, []);

  useEffect(() => {
    dispatch(getUserJournalRequest());
  }, [dispatch]);

  const handleEditClick = (journal) => {
    setSelectedJournal(journal);
    setModalShow(true);
  };

  const handleDeleteClick = (journal) => {
    setSelectedJournal(journal);
    setDeleteModalShow(true); // Open delete modal
  };

  const handleDeleteConfirm = () => {
    if (selectedJournal) {
      dispatch(deleteJournalRequest({ id: selectedJournal._id }));
      setDeleteModalShow(false);
    }
  };
  console.log("userjournal", userJournal);

  const iconStyle =
    userRole === "Coach" ? { color: "#0071BD " } : { color: "#009345" };
  return (
    <div className="py-4">
      <h4 className="fs_25 ff-gotham-bold color_black2 fw-bold mb-0">
        My Journals
      </h4>

      {!userJournal || userJournal.length === 0 ? (
        <div
          style={{ height: "400px" }}
          className="d-flex align-items-center justify-content-center"
        >
          <p className="ff-gotham-bold fs_18">No journals found</p>
        </div>
      ) : (
        <Row className="">
          {userJournal?.map((value, index) => (
            <Col className="mt-4" key={index} md={6}>
              <div className="d-md-flex gap-3 bg-white p-4 rounded-3">
                <img
                  className="journal-image object-fit-cover"
                  src={value?.images}
                  alt={value?.title}
                />
                <div className="d-flex flex-column justify-content-between">
                  <p className="fs_15 ff-gotham-normal color_black3 mb-0 fw-normal">
                    {value?.title}
                  </p>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="color_theme ff-gotham-normal mb-0 me-2">
                      {formatDate(value?.createdAt)}
                    </p>
                    <div className="d-flex align-items-center gap-2">
                      <Link to={`/fieldhouse-dashboard/journal/${value?._id}`}>
                        <FaEye style={iconStyle} className="me-2" />
                      </Link>
                      <FaPen
                        style={iconStyle}
                        onClick={() => handleEditClick(value)}
                        className="me-2 cursor_pointer"
                      />
                      <FaTrash
                        style={iconStyle}
                        onClick={() => handleDeleteClick(value)} // Handle delete click
                        className="me-2 cursor_pointer"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      )}

      {selectedJournal && (
        <>
          <EditJournalModal
            show={modalShow}
            handleClose={() => setModalShow(false)}
            journal={selectedJournal}
          />
          <DeleteJournalModal
            show={deleteModalShow}
            onClose={() => setDeleteModalShow(false)} // Close delete modal
            journalId={selectedJournal._id}
            onConfirm={handleDeleteConfirm} // Confirm delete action
          />
        </>
      )}
    </div>
  );
};

export default UserJournal;
