import React, { useEffect, useState } from "react";
import "./AllSongs.css";
import { getAllSongs } from "../../../../services/TopSongsService";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import playIcon from "../../../../assets/image/svg/playicon.svg";
import pauseIcon from "../../../../assets/image/svg/pauseIcon.svg";
import headPhone from "../../../../assets/image/svg/headphoneIcon.svg";

const AllSongs = () => {
  const [songs, setSongs] = useState([]);
  const [currentSong, setCurrentSong] = useState(null);
  const [audio, setAudio] = useState(new Audio());
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // Fetch top songs on mount
    const fetchTopSongs = async () => {
      const topSongs = await getAllSongs();
      setSongs(topSongs);
    };

    fetchTopSongs();

    // Cleanup on component unmount
    return () => {
      if (audio) {
        audio.pause();
      }
    };
  }, []);

  const handlePlayPause = (songId, songFile) => {
    if (currentSong === songId) {
      audio.paused ? audio.play() : audio.pause();
    } else {
      if (audio) {
        audio.pause();
      }
      const newAudio = new Audio(songFile);
      setAudio(newAudio);
      setCurrentSong(songId);
      newAudio.play();
    }
  };

  const handleAudioTimeUpdate = () => {
    const currentTime = (audio.currentTime / audio.duration) * 100;
    setProgress(currentTime);
  };

  useEffect(() => {
    if (audio) {
      audio.addEventListener("timeupdate", handleAudioTimeUpdate);
      return () => {
        audio.removeEventListener("timeupdate", handleAudioTimeUpdate);
      };
    }
  }, [audio]);

  return (
    <div className="top-songs">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h2 className="ff-gotham-bold mb-0">Top Songs</h2>
        <Link
          className="ff-gotham-bold color_theme text-decoration-underline"
          to={"/fieldhouse-dashboard/songs"}
        >
          View all
        </Link>
      </div>
      <ul>
        <Row>
          {songs.map((song) => (
            <Col className="px-2" sm={6}>
              <li key={song._id} className="song-item px-3">
                <div className="song-info">
                  <div className="song-icon">
                    <img
                      style={{ width: "27px", height: "27px" }}
                      src={headPhone}
                      alt="icon"
                    />
                  </div>
                  <div className="song-details">
                    <p className="song-title text-capitalize mb-0">
                      {song.name}
                    </p>
                    <p className="song-artist mb-0 text-capitalize">
                      {song.genre}
                    </p>
                  </div>
                </div>
                <button
                  className="play-pause-btn"
                  onClick={() => handlePlayPause(song._id, song.songFile)}
                >
                  {currentSong === song._id && !audio.paused ? (
                    <img src={pauseIcon} alt="" />
                  ) : (
                    <img src={playIcon} alt="" />
                  )}
                </button>
              </li>
            </Col>
          ))}
        </Row>
      </ul>
    </div>
  );
};

export default AllSongs;
